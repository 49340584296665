import { graphql } from "gatsby"
import React from "react"
import Layout from "../layouts/Layout"
import SiteMetadata from "../components/SiteMetadata"
import { useIntl } from "gatsby-plugin-intl"

const PrivacyPage = ({ data }) => {
  const intl = useIntl()

  return (
    <Layout>
      {intl.locale === "en" && (
        <SiteMetadata title="Privacy | MandW Entertainment"
                      description="MandW Entertainment Privacy Policy"
                      image={data.social.publicURL}
                      slug={"privacy"} />
      )}
      {intl.locale === "ja" && (
        <SiteMetadata title="Privacy | MandW Entertainment"
                      description="MandW Entertainmentプライバシーポリシー"
                      image={data.social.publicURL}
                      slug={"privacy"} />
      )}

      <div className="bg-gray-100">
        <div className="container py-12 lg:pb-16">
          <div className="flex flex-wrap">
            <div className="w-full md:w-1/2 xl:w-3/5 pb-8 md:pb-0">
              <h1 className="text-3xl leading-tight font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                {intl.formatMessage({ id: "privacy_title" })}
              </h1>
              <h2 className="text-xl leading-tight font-semibold tracking-tight text-blue-600 pt-12 sm:text-2xl">
                {intl.formatMessage({ id: "privacy_introduction_title" })}
              </h2>
              <div className="mt-4 leading-loose">
                {intl.formatMessage({ id: "privacy_introduction_detail" })}
              </div>
              <h2 className="text-xl leading-tight font-semibold tracking-tight text-blue-600 pt-12 sm:text-2xl">
                {intl.formatMessage({ id: "privacy_types_of_information_title" })}
              </h2>
              <div className="mt-4 leading-loose">
                {intl.formatMessage({ id: "privacy_types_of_information_detail" })}
                <ul className="list-disc list-inside pt-6">
                  <li>{intl.formatMessage({ id: "privacy_types_of_information_1" })}</li>
                  <li>{intl.formatMessage({ id: "privacy_types_of_information_2" })}</li>
                  <li>{intl.formatMessage({ id: "privacy_types_of_information_3" })}</li>
                  <li>{intl.formatMessage({ id: "privacy_types_of_information_4" })}</li>
                </ul>
              </div>
              <h3 className="text-xl leading-tight font-semibold tracking-tight text-blue-600 pt-12 sm:text-xl">
                {intl.formatMessage({ id: "privacy_ad_title" })}
              </h3>
              <div className="mt-4 leading-loose">
                {intl.formatMessage({ id: "privacy_ad_detail" })}
              </div>
              <ul className="list-disc list-inside pt-6">
                <li>{intl.formatMessage({ id: "privacy_ad_admob" })}</li>
                <li className="list-none pl-6 pt-1"><a href={intl.formatMessage({ id: "privacy_ad_admob_url" })} target="_blank" rel="noopener noreferrer">{intl.formatMessage({ id: "privacy_ad_admob_url" })}</a></li>
                <li className="pt-6">{intl.formatMessage({ id: "privacy_ad_applovin" })}</li>
                <li className="list-none pl-6 pt-1"><a href={intl.formatMessage({ id: "privacy_ad_applovin_url" })} target="_blank" rel="noopener noreferrer">{intl.formatMessage({ id: "privacy_ad_applovin_url" })}</a></li>
                <li className="pt-6">{intl.formatMessage({ id: "privacy_ad_unity_ads" })}</li>
                <li className="list-none pl-6 pt-1"><a href={intl.formatMessage({ id: "privacy_ad_unity_ads_url" })} target="_blank" rel="noopener noreferrer">{intl.formatMessage({ id: "privacy_ad_unity_ads_url" })}</a></li>
              </ul>
              <h3 className="text-xl leading-tight font-semibold tracking-tight text-blue-600 pt-12 sm:text-xl">
                {intl.formatMessage({ id: "privacy_analytics_title" })}
              </h3>
              <div className="mt-4 leading-loose">
                {intl.formatMessage({ id: "privacy_analytics_detail" })}
              </div>
              <ul className="list-disc list-inside pt-6">
                <li>{intl.formatMessage({ id: "privacy_analytics_firebase" })}</li>
                <li className="list-none pl-6 pt-1"><a href={intl.formatMessage({ id: "privacy_analytics_firebase_url" })} target="_blank" rel="noopener noreferrer">{intl.formatMessage({ id: "privacy_analytics_firebase_url" })}</a></li>
              </ul>
              <h2 className="text-xl leading-tight font-semibold tracking-tight text-blue-600 pt-12 sm:text-xl">
                {intl.formatMessage({ id: "privacy_change_title" })}
              </h2>
              <div className="mt-4 leading-loose">
                {intl.formatMessage({ id: "privacy_change_detail" })}
              </div>
              <h2 className="text-xl leading-tight font-semibold tracking-tight text-blue-600 pt-12 sm:text-xl">
                {intl.formatMessage({ id: "privacy_contact_title" })}
              </h2>
              <div className="mt-4 leading-loose">
                {intl.formatMessage({ id: "privacy_contact_detail" })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default PrivacyPage

export const query = graphql`
  query {
    social: file(relativePath: { eq: "social.png" }) {
      childImageSharp {
        fluid(maxWidth: 600, maxHeight: 480, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
      publicURL
    }
  }
`
